.grid-container {
  display: grid;
  gap: 15px;
  width: 100%;
  /* at the largest, a 3 column grid */
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
}

.grid-container .grid-item {
  /* make sure your widget preview fits within these dimensions */
  width: 270px;
  height: 120px;
  /* scroll extra */
  overflow-x: auto;
  overflow-x: auto;
  /* Center horizontally*/
  margin: auto;
}

.grid-item .card {
  width: 100%;
  height: 100%;
}

.grid-item .card .title {
  padding-top: 6px;
  padding-bottom: 10px;
}

.grid-item .toggle-checkbox {
  padding: 0;
  position: static;
  display: block;
  float: left;
  align-items: normal;
  height: 100%;
}

.grid-item .toggle-checkbox input[type='checkbox'] {
  /* hide entire checkbox */
  appearance: none;

  position: static;
  border-radius: 4px 0 0 4px;
  padding: 0;
  width: 0;
  height: 100%;
  margin: 0;
  opacity: 1;
}

.grid-item .toggle-checkbox input[type='checkbox'] {
  border-left: solid 5px red;
}

.grid-item .toggle-checkbox input[type='checkbox']:checked {
  border-left: solid 5px green;
}
